<template lang="pug">
.empty-state.m-auto.d-flex.flex-column.justify-content-center.align-items-center
  img.empty-state-image(:src="image")
  om-heading.text-center(h2) {{ title }}
  om-body-text.empty-state-description.text-center(bt400lg) {{ description }}
  om-link.text-nowrap.font-size-1(
    v-if="hasNavigationClickListener"
    primary
    big
    withIconRight
    @click="navigate"
  ) {{ $t('customTheme.chooser.block.empty.link') }}
</template>

<script>
  export default {
    name: 'ListEmptyState',
    props: {
      title: { type: String, required: true },
      description: { type: String, required: false },
      image: { type: String, required: true },
    },
    computed: {
      hasNavigationClickListener() {
        return this.$listeners && this.$listeners.navigateTo;
      },
    },
    methods: {
      navigate() {
        this.$emit('navigateTo');
      },
    },
  };
</script>

<style lang="sass">
  .empty-state
    row-gap: 1.25rem
    max-width: min(37.5rem, 90%)
    &-image
      width: auto
      height: 11.5rem

    &-description
      line-break: auto
</style>
